<template >
  <div class="header">
    <!-- 移动端的左侧菜单栏 -->
    <div class="move-tab" style="display: none;">
      <ul :class="{nav_menu:true,expand:isActive}">
        <li class="nav_menu-item">
          <a href='/' class="item-title">Home</a>
        </li>
        <li class="nav_menu-item" @click="expandTab" v-for="(item,index) in topList" :key="index">
          <a :href='item.url' class="item-title" >{{item.name}}</a>
          <span class="icon-expand" v-if="item.children&&item.children.length>0||item.id===3">
            <svg
              t="1718260283357"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2314"
              width="20"
              height="20"
            >
              <path
                d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                fill="#ffffff"
                p-id="2315"
              />
            </svg>
          </span>
          <ul
            :class="{'second-tab':true,expand_sec:isSecActive}"
            v-if="item.id!=3 &&item.children && item.children.length > 0 "
          >
            <li v-for="(sonItem, sonIndex) in item.children" :key="sonIndex">
              <router-link :to="sonItem.url">{{ sonItem.name }}</router-link>
            </li>
          </ul>
          <ul class="second-tab" v-if="item.id==3">
            <li v-for="(item, index) in firstLevel.slice(0,4)" :key="item.id">
              <router-link
                :to="{ name: 'products', query: { category_id: item.id } }"
              >{{ item.name }}</router-link>
            </li>
          </ul>
        </li>
        <li class="nav_menu-item">
          <a href='/contact' class="item-title">Contact US</a>
        </li>
        <!-- 控制隐藏的按钮 -->
        <div id="toggleMenu" :class="{dele:isDelete}" @click="toggleActive">
          <svg
            t="1718419459346"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4291"
            width="30"
            height="30"
          >
            <path
              d="M964.794062 100.92562 923.057495 59.190077 511.999488 470.263433 100.938412 59.190077 59.203892 100.92562 470.263945 512 59.232544 923.045727 100.968088 964.78127 511.999488 553.736567 923.057495 964.8089 964.794062 923.073356 553.732985 512Z"
              fill="#CCCCCC"
              p-id="4292"
            />
          </svg>
        </div>
      </ul>
    </div>

    <div class="container">
      <div class="tabulation" style="display: none;" @click="toggleActive">
        <svg
          t="1718186797314"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="5022"
          width="30"
          height="30"
        >
          <path
            d="M98.357122 818.73194c-14.213723 0-25.764803 11.532661-25.764803 25.76071 0 14.233166 11.55108 25.770943 25.764803 25.770943M922.866648 818.73194 98.128925 818.73194c-14.21884 0-25.770943 11.532661-25.770943 25.76071 0 14.233166 11.552104 25.770943 25.770943 25.770943l824.737724 0c14.213723 0 25.764803-11.538801 25.764803-25.770943C948.633498 830.262554 937.081395 818.73194 922.866648 818.73194zM98.357122 483.770052c-14.213723 0-25.764803 11.537777-25.764803 25.76992 0 14.229073 11.55108 25.764803 25.764803 25.764803M922.866648 483.770052 98.128925 483.770052c-14.21884 0-25.770943 11.537777-25.770943 25.76992 0 14.229073 11.552104 25.764803 25.770943 25.764803l824.737724 0c14.213723 0 25.764803-11.537777 25.764803-25.764803C948.633498 495.307829 937.081395 483.770052 922.866648 483.770052zM98.357122 148.815327c-14.213723 0-25.764803 11.539824-25.764803 25.768897 0 14.227026 11.55108 25.76378 25.764803 25.76378M98.128925 200.346981l824.737724 0c14.213723 0 25.764803-11.536754 25.764803-25.76378 0-14.229073-11.55108-25.768897-25.764803-25.768897L98.128925 148.814304c-14.21884 0-25.770943 11.539824-25.770943 25.768897C72.359005 188.81125 83.911108 200.346981 98.128925 200.346981z"
            fill="#2c2c2c"
            p-id="5023"
          />
        </svg>
      </div>
      <div class="l">
        <a href="/" class="logo">
          <img src="@/assets/imgs/head_logo.png" />
        </a>
        <div class="company-name">
          <div class="company-name-cn">广东彩龙新材料股份有限公司</div>
          <div class="company-name-en">GUANGDONG CAILONG NEW MATERIAL CO., LTD</div>
        </div>
      </div>
      <div class="r">
        <ul class="nav_menu">
          <li class="nav_menu-item">
            <router-link class="item-title" to="/">Home</router-link>
          </li>
          <li class="nav_menu-item" v-for="(item,index) in topList" :key="index">
            <router-link class="item-title" :to="item.url">{{item.name}}</router-link>
            <span class="iconfont icon-down" v-if="item.children&&item.children.length>0||item.id===3"></span>
            <ul class v-if="item.id!==3 &&item.children && item.children.length > 0">
              <li v-for="(sonItem, sonIndex) in item.children" :key="sonIndex">
                <router-link :to="sonItem.url">{{ sonItem.name }}</router-link>
              </li>
            </ul>
            <ul v-if="item.id===3">
              <li v-for="(item, index) in firstLevel.slice(0,5)" :key="item.id">
                <router-link
                  :to="{ name: 'products', query: { category_id: item.id } }"
                >{{ item.name }}</router-link>
              </li>
            </ul>
          </li>
          <li class="nav_menu-item">
            <router-link class="item-title" to="/contact">Contact US</router-link>
          </li>
        </ul>
        <div class="r-more" :style="{display:is_show?'none':'flex'}">
          <a @click="changeSearch" id="search-btn" class="iconfont icon-search"></a>
          <a href="https://gdcailong.com/">
            <span id="CN">EN</span>/
            <span id="CN">CN</span>
          </a>
        </div>
        <div class="head-search" :style="{display:is_show?'flex':'none'}">
          <span class="iconfont icon-search" ></span>
          <input placeholder="产品搜索..." @keyup.enter="search(keyword)" v-model="keyword" name="keyword" value autocomplete="off" type="text" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topList: [],
        is_show:false,
        keyword:'',
        productCategory: [],
        isActive: false,
        isSecActive: false,
        isDelete: true,
        expandedUls: [],
        newTop:{},
        firstLevel:[],
        companyList:[]
    };
  },
  mounted() {
    this.getTopList();
    this.getProductCategory();
    this.getContact()
  },
  watch: {
    "$route.query.category_id": {
      handler(newCategoryId) {
        this.activeFirstLevel = this.productCategory.find(
          item => item.id === parseInt(newCategoryId)
        );
        // 根据新的category_id获取产品列表（如果需要）
        // this.getProduct(newCategoryId);
      },
      immediate: true // 立即执行一次，以便在组件加载时根据URL中的category_id更新状态
    }
  },
  methods: {
    async getTopList() {
        const res = await this.$request.get('/ht/navigations/getPaginate')
        if (res.data.code === 0) {
          this.topList = res.data.data

          //存储分类
          const categoriesMap = {};

          // 遍历所有项目
          this.topList.forEach(item => {
            if (item.parent_id === 0) {
              // 如果是顶级分类，直接添加到映射中
              categoriesMap[item.id] = { ...item, children: [] };
            } else {
              // 如果不是顶级分类，找到对应的一级分类，并将其添加到子分类列表中
              const parentCategory = categoriesMap[item.parent_id];
              if (parentCategory) {
                parentCategory.children.push({ ...item });
              }
            }
          });
          const categories = Object.values(categoriesMap);
          this.topList = categories.filter(item => item.id !== 1 && item.id !== 6)
        }
      },

    async getProductCategory() {
      const res = await this.$request.post("/ht/productcategory/getPaginate", {
        fil: 5
      });
      if (res.data.code === 0) {
        this.productCategory = res.data.data;
          this.firstLevel = this.productCategory.filter(item=>item.is_show!==0)
      }
    },
    async getContact(){
        const res = await this.$request.get('/ht/contact/getPaginate') 
        if (res.data.code===0) {
          this.companyList=res.data.data.data[0]
        }
      },
    //搜索功能
    search(keyword) {
        this.$router.push({
          path: "/products",
          query: {
            name: keyword
          }
        })
        this.is_show=!this.is_show
      },
    //显示查询
    changeSearch(){
      console.log(111);
        this.is_show=!this.is_show
    },
    toggleActive() {
      this.isActive = !this.isActive;
      this.isDelete = !this.isDelete;
    },
    expandTab(event) {
    // 阻止事件冒泡到其他元素
    event.stopPropagation();

    // 获取当前点击的元素
    const target = event.target;

    // 递归查找最近的li父元素
    function findClosestLiParent(element) {
      if (element.tagName === 'LI') {
        return element;
      } else if (element.parentElement) {
        return findClosestLiParent(element.parentElement);
      } else {
        return null;
      }
    }

    // 找到最近的li父元素
    const liElement = findClosestLiParent(target);

    // 如果找到的li父元素存在且包含nav_menu-item类名
    if (liElement && liElement.classList.contains("nav_menu-item")) {
      // 获取该li元素下的ul元素
      const ulElement = liElement.querySelector('ul');
      if (ulElement) {
        // 检查当前ul元素是否已经在expandedUls数组中
        const index = this.expandedUls.indexOf(ulElement);
        if (index !== -1) {
          // 如果在数组中，移除它，表示收起列表
          this.expandedUls.splice(index, 1);
          ulElement.classList.remove('expand_sec');
        } else {
          // 如果不在数组中，添加它，表示展开列表
          // 同时，移除其他已展开的列表
          this.expandedUls.forEach(ul => ul.classList.remove('expand_sec'));
          this.expandedUls = [ulElement];
          ulElement.classList.add('expand_sec');
        }
      }
    }
  }
  }
};
</script>
<style lang="scss">
  .is_show{
    display: none;
  }
</style>